import React, { useState } from 'react';
import '../App.css';

const ProductCard = ({ name, price, sizes, colors, defaultImage, secondaryImage, addToCart }) => {
  const [image, setImage] = useState(defaultImage);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setImage(secondaryImage);
  };

  const handleMouseLeave = () => {
    setImage(defaultImage);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size === selectedSize ? null : size);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color === selectedColor ? null : color);
  };

  const handleAddToCart = (event) => {
    if (selectedSize && selectedColor) {
      const productToAdd = {
        name,
        price: parseFloat(price[0].replace('$', '')),
        image: defaultImage,
        size: selectedSize,
        color: selectedColor,
        quantity: 1,
      };

      addToCart(productToAdd);
      setSelectedSize(null);
      setSelectedColor(null);

      // Obtener la posición del botón
      const buttonRect = event.target.getBoundingClientRect();
      const buttonX = buttonRect.left + window.pageXOffset;
      const buttonY = buttonRect.top + window.pageYOffset;

      // Establecer las coordenadas de la burbuja
      setBubblePosition({ x: buttonX, y: buttonY });

      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } else {
      alert("Por favor selecciona talla y color antes de agregar al carrito.");
    }
  };

  const allSizes = ['XS', 'S', 'M', 'L', 'XL'];

  return (
    <div className="product-card">
      <div className="image-container">
        <img 
          src={image} 
          alt={name} 
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave}
        />
        <div className="size-banner">
          Tallas: {allSizes.map((size) => (
            <span
              key={size}
              onClick={() => handleSizeSelect(size)}
              className={sizes.includes(size) ? `selectable ${size === selectedSize ? 'selected' : ''}` : ""}
              style={{
                textDecoration: sizes.includes(size) ? "none" : "line-through",
                background: sizes.includes(size)
                  ? "none"
                  : "linear-gradient(-45deg, #f00 25%, transparent 25%, transparent 75%, #f00 75%, #f00)",
                WebkitBackgroundClip: "text",
                color: sizes.includes(size) ? "#000" : "#90A6AD",
                marginRight: "5px"
              }}
            >
              {size}
            </span>
          ))}
        </div>
      </div>
      <div className="color-container">
        {colors.map((color, index) => (
          <span
            key={index}
            onClick={() => handleColorSelect(color)}
            className={`selectable ${color === selectedColor ? 'selected' : ''}`}
            style={{
              backgroundColor: color,
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              display: "inline-block",
              margin: "0 5px"
            }}
          ></span>
        ))}
      </div>
      <div className="product-details">
        <h2>{name}</h2>
        <p>
          {price[0]}
          {price.length > 1 && (
            <span style={{ color: "#90A6AD", textDecoration: "line-through", marginLeft: "5px" }}>
              {price[1]}
            </span>
          )}
        </p>

        <button onClick={handleAddToCart}>Agregar al carrito</button>
        {showSuccess && (
          <div className={`success-bubble ${showSuccess ? 'show' : ''}`} style={{ top: bubblePosition.y, left: bubblePosition.x }}>
            Agregado con éxito
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
