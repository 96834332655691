import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const ResumenPedido = ({ cart, costoEnvio }) => {
  // Calcular el subtotal sumando los precios totales de todos los productos en el carrito
  const subtotal = cart.reduce((acc, product) => acc + (product.price * product.quantity), 0);

  // Calcular el total sumando el subtotal y el costo de envío
  const total = subtotal + costoEnvio;

  return (
    <div>
      <h2>Resumen del Pedido</h2>
      <div>
        {/* Mapear cada producto en el carrito */}
        {cart.map((product, index) => (
          <p key={index}>
            {/* Nombre del producto, cantidad y total */}
            {product.name} → ${product.price} x {product.quantity} = ${product.price * product.quantity}
          </p>
        ))}
        {/* Mostrar subtotal, costo de envío y total */}
        <p>Subtotal: ${subtotal.toFixed(2)}</p>
        <p>Costo de Envío: ${costoEnvio.toFixed(2)}</p>
        <p>Total: ${total.toFixed(2)}</p>
      </div>
      {/* Agrega un enlace al componente FinalizarCompra */}
      <Link to="/finalizar-compra">
        <button className="resumen-pedido-button">Finalizar Compra</button>
      </Link>
      <img src="/resumen_pedido_image.png" alt="Descripción de la imagen para móviles" className="resumen-pedido-image" />
    </div>
  );
};

export default ResumenPedido;
