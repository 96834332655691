import React, { useState } from 'react';
import Carrito from './Carrito'; // Importa el componente del carrito

const Header = ({ cart }) => { // Recibe el carrito como prop
    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        } else {
            const url = `/#${sectionId}`;
            window.location.href = url;
        }
    };

    return (
        <div>
            <div className="free-shipping-banner">
                <span className="free-shipping-text ">ENVÍOS GRATIS A PARTIR DE 999 MXN</span>
                <Carrito cart={cart} />
            </div>
            <header>
                <nav className="navbar">
                    <div className="logo">
                        <a href="/">
                            <img src="/main_logo.png" alt="machine learning en espanol" />
                        </a>
                    </div>
                    <div className={`hamburger ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <ul className="nav-links">
                        <li><a href="/proyectos-machine-learning">Soluciones</a></li>
                        <li><a href="/blog-machine-learning-espanol">Blog</a></li>
                        <li><a href="#" onClick={() => scrollToSection('pricing')}>Precios</a></li>
                        <li><a href="/afiliados">Afiliados</a></li>
                        <li><a href="/algorithmica-documentacion">Docs</a></li>
                    </ul>
                </nav>
            </header>
        </div>
    );
};

export default Header;
