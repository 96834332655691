import React, { useState } from 'react';

const FormularioEnvio = ({ onSubmit }) => {
  const [envio, setEnvio] = useState({
    ciudad: '',
    estado: '',
    direccion: '',
    codigoPostal: ''
  });

  // Maneja el cambio en los campos del formulario y actualiza el estado en el componente padre
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnvio(prevEnvio => ({
      ...prevEnvio,
      [name]: value
    }));
    // Propaga los cambios al componente padre (FinalizarCompra)
    onSubmit({
      ...envio,
      [name]: value
    });
  };

  return (
    <form>
      <h2>¿A dónde te envíamos, galán?</h2>
      <label htmlFor="ciudad">Ciudad:</label>
      <input
        type="text"
        id="ciudad"
        name="ciudad"
        value={envio.ciudad}
        onChange={handleChange}
        required
      />
      <label htmlFor="estado">Estado:</label>
      <input
        type="text"
        id="estado"
        name="estado"
        value={envio.estado}
        onChange={handleChange}
        required
      />
      <label htmlFor="direccion">Dirección:</label>
      <input
        type="text"
        id="direccion"
        name="direccion"
        value={envio.direccion}
        onChange={handleChange}
        required
      />
      <label htmlFor="codigoPostal">Código Postal:</label>
      <input
        type="text"
        id="codigoPostal"
        name="codigoPostal"
        value={envio.codigoPostal}
        onChange={handleChange}
        required
      />
    </form>
  );
};

export default FormularioEnvio;
