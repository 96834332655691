// Dentro de CartItem.js

import React, { useState, useEffect } from 'react';
import '../App.css'; 

const CartItem = ({ product, onRemove, updateSubtotal, updateQuantity }) => {
  // Cambié la lógica para manejar la cantidad dentro del estado de CartItem
  const [quantity, setQuantity] = useState(product.quantity);

  useEffect(() => {
    updateSubtotal();
  }, [quantity, updateSubtotal]);

  // Utilizamos useEffect para actualizar la cantidad si cambia el producto
  useEffect(() => {
    setQuantity(product.quantity);
  }, [product]);

  const handleIncrease = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    updateQuantity(product.name, newQuantity);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      updateQuantity(product.name, newQuantity);
    }
  };

  return (
    <div className="cart-item">
      <img src={product.image} alt={product.name} />
      <div className="item-info">
        <p>{product.name}</p>
        <p>Talla: {product.size}</p>
        <div className="color-circle" style={{ backgroundColor: product.color }}></div>
        <p>Precio: ${product.price}</p>
      </div>
      <div className="quantity-widget">
        <button onClick={handleDecrease}>-</button>
        <span>{quantity}</span>
        <button onClick={handleIncrease}>+</button>
      </div>
      <button className="remove-button" onClick={() => onRemove(product)}>
        Eliminar
      </button>
    </div>
  );
};

export default CartItem;
