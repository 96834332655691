import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';

const PaymentForm = ({ cart, datosCliente }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [clientSecret, setClientSecret] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('card');  // Por defecto, selecciona tarjeta

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await axios.post('/api/create-payment-intent/', {
                    cart: cart,
                });
                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error al obtener el secret del cliente:', error);
            }
        };

        fetchClientSecret();
    }, [cart]);

    const handleSuccessfulPayment = async (paymentIntentId) => {
        try {
            // Aquí haces la petición al backend para enviar el correo
            await axios.post('/api/send-email/', {
                paymentIntentId: paymentIntentId,
                email: datosCliente.email,  // Suponiendo que aquí tienes el correo del cliente
            });
            console.log('Correo enviado correctamente.');
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const billingDetails = {
            name: `${datosCliente ? datosCliente.nombre : ''} ${datosCliente ? datosCliente.apellido : ''}`,
            email: datosCliente ? datosCliente.email : '',
        };

        console.log(billingDetails)

        try {
            let payload;

            if (paymentMethod === 'oxxo') {
                // Pago con OXXO
                payload = await stripe.confirmOxxoPayment(clientSecret, {
                    payment_method: {
                        type: 'oxxo',
                        billing_details: billingDetails,
                    },
                });
            } else {
                // Pago con tarjeta
                payload = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: billingDetails,
                    },
                });
            }

            if (payload.error) {
                console.error('Error al procesar el pago:', payload.error.message);
                // Manejar el error en tu aplicación
            } else {
                console.log('Pago exitoso:', payload.paymentIntent);
                // Manejar el éxito del pago en tu aplicación
                // alert('¡Pago exitoso! Gracias por tu compra.');
                handleSuccessfulPayment(payload.paymentIntent.id); // Llamamos a la función para enviar el correo
            }
        } catch (error) {
            console.error('Error al confirmar el pago con Stripe:', error);
        }
    };

    return (
        <div>
            <div className="payment-method-tabs">
                <button
                    className={`tab-button ${paymentMethod === 'card' ? 'active' : ''}`}
                    onClick={() => setPaymentMethod('card')}
                >
                    Pagar con Tarjeta
                </button>
                <button
                    className={`tab-button ${paymentMethod === 'oxxo' ? 'active' : ''}`}
                    onClick={() => setPaymentMethod('oxxo')}
                >
                    Pagar con OXXO
                </button>
            </div>

            <div className="payment-method-content">
                {paymentMethod === 'card' && (
                    <form onSubmit={handleSubmit}>
                        <CardElement />
                        <button type="submit" disabled={!stripe}>
                            Pagar con Tarjeta
                        </button>
                    </form>
                )}

                {paymentMethod === 'oxxo' && (
                    <div>
                        <button onClick={handleSubmit} disabled={!stripe}>
                            Generar pago en OXXO
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentForm;
