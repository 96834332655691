import React, { useState, useEffect } from 'react';
import '../App.css';

const Modal = () => {
  const [showModal, setShowModal] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

      if (scrollPercentage >= 50 && !hasModalBeenShown) {
        setShowModal(true);
        setHasModalBeenShown(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasModalBeenShown]);

  const closeModal = () => {
    setShowModal(false);
    // Guardar la marca de tiempo actual en localStorage
    localStorage.setItem('modalClosedAt', Date.now());
  };

  useEffect(() => {
    // Obtener la marca de tiempo en la que se cerró el modal por última vez
    const modalClosedAt = localStorage.getItem('modalClosedAt');
    if (modalClosedAt) {
      const now = Date.now();
      const hoursPassed = (now - parseInt(modalClosedAt, 10)) / (1000 * 60 * 60); // Calcular horas transcurridas
      // Mostrar el modal nuevamente solo si han pasado al menos 10 horas
      if (hoursPassed >= 10) {
        setHasModalBeenShown(false); // Permitir que se muestre el modal nuevamente
      } else {
        setHasModalBeenShown(true); // Evitar que se muestre el modal
      }
    }
  }, []);

  return (
    <div className={`modal ${showModal ? 'show' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
        <img src="modal.png" alt="Imagen Modal" />
      </div>
    </div>
  );
};

export default Modal;
