import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PaymentForm from './PaymentForm';
import FormularioCliente from './FormularioCliente';
import FormularioEnvio from './FormularioEnvio';
import ResumenPedidoConImagen from './ResumenPedidoConImagen';
import '../App.css';

const FinalizarCompra = () => {
    const [cart, setCart] = useState([]);
    const [datosCliente, setDatosCliente] = useState(null);
    const [datosEnvio, setDatosEnvio] = useState(null);
    const [isPayment, setIsPayment] = useState(false);
    const [formulariosVisible, setFormulariosVisible] = useState(true);
    const [proceedClicked, setProceedClicked] = useState(false);

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        if (storedCart) {
            setCart(storedCart);
        }
    }, []);

    const handleClienteSubmit = (datosCliente) => {
        setDatosCliente(datosCliente);
    };

    const handleEnvioSubmit = (datosEnvio) => {
        setDatosEnvio(datosEnvio);
    };

    const handleProceedToPayment = async () => {
        if (datosCliente && datosEnvio) {
            setProceedClicked(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });

            try {
                await handleFinalizarCompra();
                setTimeout(() => {
                    setFormulariosVisible(false);
                    setIsPayment(true);
                }, 500); // Tiempo de la transición
            } catch (error) {
                console.error('Error al finalizar la compra:', error);
                alert('Hubo un error al finalizar la compra. Por favor, inténtalo de nuevo.');
            }
        } else {
            alert('Por favor, completa los datos del cliente y de envío.');
        }
    };

    const handleFinalizarCompra = async () => {
        if (datosCliente && datosEnvio) {
            const datosCompra = {
                cliente: datosCliente,
                envio: datosEnvio,
                cart: cart
            };

            try {
                const response = await axios.post('/api/procesar-compra/', datosCompra);
                // console.log(response.data);
                // alert('¡Compra finalizada!');
            } catch (error) {
                console.error('Error al finalizar la compra:', error);
                alert('Hubo un error al finalizar la compra. Por favor, inténtalo de nuevo.');
                throw error;
            }
        } else {
            console.log("No se han recibido todos los datos necesarios.");
            throw new Error("No se han recibido todos los datos necesarios.");
        }
    };

    return (
        <div className="finalizar-compra-page">
            <div className="finalizar-compra-container">
                {formulariosVisible && (
                    <div className="formularios-container">
                        <div className={`formulario ${proceedClicked ? 'move-left' : ''}`}>
                            <FormularioCliente onSubmit={handleClienteSubmit} />
                        </div>
                        <div className={`formulario ${proceedClicked ? 'move-left' : ''}`}>
                            <FormularioEnvio onSubmit={handleEnvioSubmit} />
                        </div>
                        <button onClick={handleProceedToPayment}>Proceder al pago</button>
                    </div>
                )}

                {isPayment && (
                    <div className="payment-form animate-slide-up show">
                        <PaymentForm cart={cart} datosCliente={datosCliente} datosEnvio={datosEnvio} />
                    </div>
                )}
            </div>

            <div className="resumen-con-imagen-container">
                <ResumenPedidoConImagen cart={cart} costoEnvio={99} />
            </div>
        </div>
    );
};

export default FinalizarCompra;
