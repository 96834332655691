import React, { useState } from 'react';
import CartItem from './CartItem';
import ResumenPedido from './ResumenPedido';
import '../App.css';

const CarritoPage = ({ cart, removeFromCart, setCart }) => {
  const [subtotal, setSubtotal] = useState(0);

  // Función para calcular el subtotal total del carrito
  const calculateSubtotal = () => {
    let total = 0;
    cart.forEach(product => {
      total += product.price * product.quantity;
    });
    return total;
  };

  // Función para actualizar el subtotal total del carrito
  const updateSubtotal = () => {
    const newSubtotal = calculateSubtotal();
    setSubtotal(newSubtotal);
  };

  // Función para actualizar la cantidad de un producto en el carrito
  const updateQuantity = (productName, newQuantity) => {
    console.log(cart)
    const updatedCart = cart.map(product => {
      if (product.name === productName) {
        return { ...product, quantity: newQuantity };
      }
      return product;
    });
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleRemove = (product) => {
    const updatedCart = cart.filter(item => item !== product);
    setCart(updatedCart);

    updateSubtotal();
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  // Costo de envío fijo
  const costoEnvio = 99;

  // Total sumando el subtotal y el costo de envío
  const total = subtotal + costoEnvio;

  return (
    <div className="carrito-page">
      <div className="cart-container">
        <ul>
          {cart.map((product, index) => (
            <li key={index}>
              <CartItem
                product={product}
                onRemove={handleRemove}
                updateSubtotal={updateSubtotal}
                updateQuantity={(productName, newQuantity) => updateQuantity(productName, newQuantity)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="resumen-container">
        <ResumenPedido cart={cart} subtotal={subtotal} costoEnvio={costoEnvio} total={total} />
      </div>
    </div>
  );
};

export default CarritoPage;
