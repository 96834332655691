import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import ImageComponent from './components/ImageComponent';
import ProductGrid from './components/ProductGrid';
import CarritoPage from './components/CarritoPage';
import FinalizarCompra from './components/FinalizarCompra';
import Modal from './components/Modal';
import Banner from './components/Banner';
import './App.css';


const products = [
  {
    name: 'Playera Casual',
    price: ['$20'],
    sizes: ['S', 'M', 'L'],
    colors: ['#5E6F89', '#7B4972', '#1B90CD'],
    defaultImage: '/im1.png',
    secondaryImage: '/im2.png'
  },
  {
    name: 'Playera Dryfit',
    price: ['$30', '$100.00'],
    sizes: ['M', 'L', 'XL'],
    colors: ['#F1F4F2', '#5A3852'],
    defaultImage: '/im3.png',
    secondaryImage: '/im4.png'
  },
  {
    name: 'Polo Dryfit',
    price: ['$40'],
    sizes: ['S', 'L', 'XL'],
    colors: ['#1A2B3B', '#A72A4C'],
    defaultImage: '/im5.png',
    secondaryImage: '/im6.png'
  },
  {
    name: 'Manga larga Dryfit',
    price: ['$50'],
    sizes: ['S', 'M'],
    colors: ['#6600CC', '#FF6600', '#0066FF'],
    defaultImage: '/im7.png',
    secondaryImage: '/im8.png'
  },
  {
    name: 'Jogger',
    price: ['$15', '$35'],
    sizes: ['S'],
    colors: ['#FFFF00', '#999999', '#FF00FF'],
    defaultImage: '/im9.png',
    secondaryImage: '/im10.png'
  },
  {
    name: 'Short Dryfit',
    price: ['$25'],
    sizes: ['M'],
    colors: ['#3366FF', '#FF3366', '#66FF33'],
    defaultImage: '/im11.png',
    secondaryImage: '/im12.png'
  }
];

function App() {
  const [cart, setCart] = useState([]);

  // Función para cargar el carrito desde el almacenamiento local al montar la aplicación
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    if (storedCart) {
      setCart(storedCart);
    }
  }, []);

  // Función para actualizar el carrito y el almacenamiento local al agregar un producto
  const addToCart = (product) => {
    const updatedCart = [...cart, product];
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  return (
    <Router>
      <div>
        <Header cart={cart} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero
                  title="Bienvenido a mi sitio web"
                  subtitle="Explora nuestras increíbles características"
                  buttonText="Empezar ahora"
                />
                <div className="product-section">
                  <ProductGrid products={products} addToCart={addToCart} />
                </div>
              </>
            }
          />
          <Route path="/pagina-del-carrito" element={<CarritoPage cart={cart} setCart={setCart} />} />
          <Route path="/finalizar-compra" element={<FinalizarCompra />} />
        </Routes>
        <Banner />
	<ImageComponent />
        <Modal />
      </div>
    </Router>
  );
}

export default App;

