import React, { useState, useEffect } from 'react';

const FormularioCliente = ({ onSubmit }) => {
  const [cliente, setCliente] = useState({
    nombre: '',
    apellido: '',
    email: '',
    telefono: ''
  });

  // Maneja el cambio en los campos del formulario y actualiza el estado en el componente
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCliente(prevCliente => ({
      ...prevCliente,
      [name]: value
    }));
  };

  // Propaga los cambios al componente padre (FinalizarCompra) usando useEffect
  useEffect(() => {
    onSubmit(cliente);
  }, [cliente, onSubmit]);

  return (
    <form>
      <h2>Jaguar, comparte tus datos:</h2>
      <label htmlFor="nombre">Nombre:</label>
      <input
        type="text"
        id="nombre"
        name="nombre"
        value={cliente.nombre}
        onChange={handleChange}
        required
      />
      <label htmlFor="apellido">Apellido:</label>
      <input
        type="text"
        id="apellido"
        name="apellido"
        value={cliente.apellido}
        onChange={handleChange}
        required
      />
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={cliente.email}
        onChange={handleChange}
        required
      />
      <label htmlFor="telefono">Teléfono:</label>
      <input
        type="tel"
        id="telefono"
        name="telefono"
        value={cliente.telefono}
        onChange={handleChange}
        required
      />
    </form>
  );
};

export default FormularioCliente;
