// Carrito.js
import React from 'react';

const Carrito = ({ cart }) => {
  return (
    <div className="carrito">
      <a href="/pagina-del-carrito"> {/* Enlace a la página del carrito */}
        <img src="/shopping.png" alt="Carrito de compras" className="carrito-icon" />
        <span style={{ color: '#FFF', fontWeight: 'bold' }}>{cart.length}</span>
      </a>
    </div>
  );
};

export default Carrito;
